import styled from "styled-components";
import { MessageProps } from "../types/props/MessageProps";
import { Link } from "../types/state/ChatState";
import Loader from "./Loader";
import Feedback from "./Feedback";

const StyledMessageDiv = styled.div`
  font-size: 1rem;
  line-height: 1.6;
  max-width: calc(100% - 2rem);
  padding: 1em;
  border-radius: 8px;
  overflow-wrap: break-word;

  @media (min-width: 768px) {
    max-width: calc(100% - 6rem);
  }
`;

const StyledAssistantMessageDiv = styled(StyledMessageDiv)`
  background-color: #f6f6f6;
  justify-self: start;
  margin-inline-end: auto;
`;

const StyledAssistantLoadingMessageDiv = styled(StyledMessageDiv)`
  justify-self: start;
  margin-inline-end: auto;
  width: 96px;
`;

const StyledUserMessageDiv = styled(StyledMessageDiv)`
  background-color: #fff5f2;
  justify-self: end;
  margin-inline-start: auto;
`;

const StyledAssistantMessageTextDiv = styled.div``;

const StyledLinkContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
`;

const StyledLink = styled.a`
  display: inline-block;
  max-width: 100%;
  margin-block-start: 0.75em;
  padding: 0.5em 0.75em;
  font-size: 0.9rem;
  background: #edf4ff;
  border: 1px solid #b7d4ff;
  border-radius: 0.375em;
  text-decoration: none;
  color: #06369e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: #e1edff;
    text-decoration: none;
  }

  &:visited {
    color: #06369e;
  }
`;

const Message = (props: MessageProps) => {
  const { id, conversationId, role, content, links, showFeedbackButton } =
    props;

  const linkList: Link[] = links || [];

  if (role === "assistant-loading") {
    return (
      <StyledAssistantLoadingMessageDiv>
        <Loader />
      </StyledAssistantLoadingMessageDiv>
    );
  }

  if (role === "assistant") {
    return (
      <StyledAssistantMessageDiv>
        <StyledAssistantMessageTextDiv>
          {content.split("\n").map((c, i) => (
            <span key={c.length.toString() + i.toString()}>
              {c}
              <br />
            </span>
          ))}
        </StyledAssistantMessageTextDiv>
        <StyledLinkContainerDiv>
          {linkList.map((l, i) => (
            <StyledLink
              href={l.link}
              target="_blank"
              rel="noopener noreferrer"
              key={i.toString() + l.title + l.link}
            >
              {l.title}
            </StyledLink>
          ))}
        </StyledLinkContainerDiv>
        <Feedback
          conversationId={conversationId}
          messageId={id}
          showFeedbackButton={showFeedbackButton}
        />
      </StyledAssistantMessageDiv>
    );
  }

  return (
    <StyledUserMessageDiv>
      {content.split("\n").map((c, i) => (
        <span key={c.length.toString() + i.toString()}>
          {c}
          <br />
        </span>
      ))}
    </StyledUserMessageDiv>
  );
};

export default Message;
