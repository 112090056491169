import styled from "styled-components";

const StyledFooter = styled.footer`
  width: 100vw;
  margin-block-start: 1.5em;
  padding-block: 1.5em;
  background-color: #F6F6F6;
  color: #A7A7A7;
`;

const StyledContainer = styled.div`
  margin-inline: auto;
  padding-inline: 1.5em;
  max-width: 720px;

  @media screen and (min-width: 720px) {
      padding-inline: inherit;
  }
`;

const StyledLinks = styled(StyledContainer)`
  display: flex;
  flex-direction: column;
  gap: .5em;
  font-size: .875rem;

  @media screen and (min-width: 720px) {
      flex-direction: inherit;
      gap: 2em;
  }

  & a {
    color: inherit;
    text-decoration: none;
  }

  & a:hover {
    text-decoration: underline;
  }
`;

const StyledCopyright = styled(StyledContainer)`
  display: flex;
  flex-direction: column;
  row-gap: .5em;
  color: inherit;
  font-size: .75rem;
  margin-block-start: 1.5em;
`;

const Footer = () => (
  <StyledFooter>
    <StyledLinks>
      <a href="/">チャット法律相談</a>
      <a href="https://www.bengo4.com/contact/">お問い合わせ</a>
      <a href="/terms-of-service/">利用規約</a>
      <a href="/privacy-policy/">プライバシーポリシー</a>
      <a href="https://www.bengo4.com/corporate/">運営会社</a>
    </StyledLinks>
    <StyledCopyright>
      <div>弁護士ドットコムは、弁護士ドットコム株式会社の登録商標です。</div>
      <div>&copy;Bengo4.com, Inc. 2005</div>
    </StyledCopyright>
  </StyledFooter>
);

export default Footer;
