import styled from "styled-components";

const StyledDiv = styled.div`

  & dl {
    padding: 1em;
    background: #F6F6F6;
    border: 1px solid #EDEDED;
    border-radius: 6px;
    line-height: 1.4;
  }

  & dt {
    font-weight: 700;
    margin-block-end: .5em;
  }
  & dd {
    margin-inline-start: 0;
    margin-block-end: .5em;

    & ul {
      padding-inline-start: 2em;
    }

    & li {
      margin-block-end: .25em;
    }
  }

  @media (min-width: 768px)
  {
    & dl {
      padding: 1.5em;
    }
  }
`;

const Disclaimer = () => (
  <StyledDiv>
    <dl>
      <dt>チャット法律相談（α版）とは？</dt>
      <dd>
        <ul>
          <li>本サービスは入力いただいた情報及び当社の無料法律相談データベース等に基づき、AIを用いて自動的にご相談の内容に対応した生成文章を提供するサービスです。</li>
          <li>本サービスにはAIが使用されており、内容の正確性及び最新性等を保証するものではありません。法的な意見を提供するものではなく、また、弁護士が提供するサービスを代替するものではありません。チャットの応答内容に従った場合でも、当社は何らの責任を負いません。</li>
          <li>具体的なトラブルがすでに生じている場合は、お早めに弁護士へ相談されることをお勧めいたします。</li>
          <li>個人情報の入力はお控えください。</li>
        </ul>
      </dd>
      <dd>詳しくは利用規約をご覧ください。</dd>
    </dl>
  </StyledDiv>
);

export default Disclaimer;
