import styled from "styled-components";

const StyledDiv = styled.div`
@media (min-width: 768px) {
  & a {
    display: flex;
    justify-content: center;
  }
}
`;

const TemporarilyUnavailable = () => (
  <StyledDiv>
    <p>アクセスの集中により、現在サービスの提供を見合わせています。再開まで今しばらくお待ちください。</p>
    <a href="https://www.bengo4.com/bbs/question/">みんなの法律相談を利用する</a>
  </StyledDiv>
);

export default TemporarilyUnavailable;
