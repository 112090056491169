import styled from "styled-components";
import { MessageCountIndicatorProps } from "../types/props/MessageCountIndicatorProps";

const StyledMessageDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375em;
  font-size: 0.875rem;

  &:before {
    content: "";
    display: block;
    width: 0.5625em;
    height: 0.5625em;
    border-radius: 50%;
  }
`;

const StyledCountUpDiv = styled(StyledMessageDiv)`
  &:before {
    background-color: #05ceaa;
  }
`;

const StyledCountStopDiv = styled(StyledMessageDiv)`
  &:before {
    background-color: #ee6c58;
  }
`;

const StyledCountEmptyDiv = styled(StyledMessageDiv)``;

const MessageCountIndicator = (props: MessageCountIndicatorProps) => {
  const { remainingMessageCount, showIndicator } = props;

  if (!showIndicator) {
    return <StyledCountEmptyDiv />;
  }

  if (remainingMessageCount > 0) {
    return (
      <StyledCountUpDiv>
        あと {remainingMessageCount} 回質問できます
      </StyledCountUpDiv>
    );
  }

  return <StyledCountStopDiv>質問回数が上限になりました</StyledCountStopDiv>;
};

export default MessageCountIndicator;
