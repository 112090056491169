import { useState } from "react";
import styled from "styled-components";
import { useChat } from "../context/ChatContext";
import { ReactComponent as SendIcon } from "../images/icon-send.svg";
import { SendButtonProps } from "../types/props/SendButtonProps";
import { PostConversationMessageResponse } from "../types/response/PostConversationMessageResponse";
import { MessageState } from "../types/state/ChatState";
import { sleep } from "../utils/sleep";

const MaxMessageCount = 5;

const StyledSendButton = styled.button`
  border: none;
  padding: 0;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  aspect-ratio: 1;
  font-size: 0;
  background-color: #f77e3e;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 56px;
    height: 56px;
    border-radius: 28px;
  }

  &:hover {
    background-color: #e86020;
  }

  &:focus-visible {
    outline: 4px solid rgba(105, 165, 249, 0.5);
    outline-offset: 2px;
  }

  &:disabled {
    background-color: #f6f6f6;
    cursor: initial;
  }

  &:disabled path {
    fill: #d9d9d9;
  }
`;

const ErrorMessage = (status: number): string => {
  switch (status) {
    case 403:
      return "エラーが発生しました。";
    default:
      return "エラーが発生しました。しばらく待ってから再度お試しください。";
  }
};

const SendButton = (props: SendButtonProps) => {
  const {
    clearUserInput,
    userInput,
    conversationId,
    cref,
    disabled,
    isChatAvailable,
    updateMessageCount,
  } = props;
  const [, addMessage] = useChat();

  // API へリクエスト中かどうかを保持する state
  const [requesting, setRequesting] = useState<boolean>(false);
  const startRequest = () => {
    setRequesting(true);
  };
  const finishedRequest = () => {
    setRequesting(false);
  };

  const onClickSend = () => {
    if (userInput.length === 0) {
      return;
    }
    const preMessages: MessageState[] = [
      {
        role: "user",
        content: userInput,
        messageId: `user-in-${Date.now()}`,
      },
    ];
    addMessage(preMessages, cref);

    clearUserInput();

    const showErrorMessage = (errorMessage: string) => {
      received = true;
      const newMessages = preMessages.filter(
        (e) => e.role !== "assistant-loading"
      );
      newMessages.push({
        role: "assistant",
        content: errorMessage,
        messageId: `error-${Date.now()}`,
      });
      addMessage(newMessages, cref);
      finishedRequest();
    };

    let received = false;
    // loading を表示
    sleep(1500)
      .then(() => {
        if (received) {
          return;
        }
        preMessages.push({
          role: "assistant-loading",
          content: "",
          messageId: `assistant-load-${Date.now()}`,
        });
        addMessage(preMessages, cref);
      })
      .catch(() => {});

    // TODO: リリース時に削除する
    const query = new URLSearchParams(window.location.search);
    const baseContextValue = query.get("bc");
    let baseContext = 0;
    if (baseContextValue !== null) {
      baseContext = Number.parseInt(baseContextValue, 10);
      if (baseContext === null || Number.isNaN(baseContext)) {
        baseContext = 0;
      }
    }

    const base = process.env.REACT_APP_CHAT_API_BASE as string;
    const url = `${base}/conversations/${conversationId}/messages`;
    let content = "";
    let assistantMessageId = "";
    let showFeedbackButton = false;
    let numMessage: number | undefined = 0;

    let tmpReceivedData = "";

    startRequest();
    try {
      fetch(url, {
        method: "post",
        body: JSON.stringify({
          message: userInput,
          bc: baseContext,
        }),
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
        },
      })
        .then(async (res) => {
          if (res.body === null) {
            throw new Error("body is null");
          }

          const reader = res.body
            .pipeThrough(new TextDecoderStream())
            .getReader();

          // eslint-disable-next-line no-constant-condition
          while (true) {
            const { done, value } = await reader.read(); // eslint-disable-line no-await-in-loop

            if (done) {
              finishedRequest();
              updateMessageCount(numMessage);
              if (tmpReceivedData.length !== 0) {
                // eslint-disable-next-line no-console
                console.warn(
                  "It is possible that not all chat messages are being output."
                );
              }
              // add link
              if (numMessage !== undefined && numMessage < MaxMessageCount) {
                const newMessages = preMessages.filter(
                  (e) => e.role !== "assistant-loading"
                );
                if (content.includes("弁護士")) {
                  newMessages.push({
                    role: "assistant",
                    content,
                    messageId: assistantMessageId,
                    showFeedbackButton,
                    links: [
                      {
                        title: "こちらから弁護士を探すことができます",
                        link: "https://www.bengo4.com/search/",
                      },
                    ],
                  });
                  addMessage(newMessages, cref);
                }
              }
              return;
            }

            const receivedData = value.split("}\n");
            for (let i = 0; i < receivedData.length; i += 1) {
              if (receivedData[i].length === 0) {
                break;
              }

              let toParseData = receivedData[i];
              if (toParseData.slice(0, 1) === "{") {
                tmpReceivedData = "";
              }

              if (tmpReceivedData.length !== 0) {
                toParseData = `${tmpReceivedData}${toParseData}`;
              }

              if (receivedData[i].slice(-1) !== "}") {
                toParseData += "}";
              }

              let parsedData: PostConversationMessageResponse | undefined;
              try {
                parsedData = JSON.parse(
                  toParseData
                ) as PostConversationMessageResponse;
                tmpReceivedData = "";
              } catch {
                if (toParseData.slice(-1) === "}") {
                  toParseData = toParseData.slice(0, -1);
                }
                tmpReceivedData = toParseData;
              }

              if (parsedData !== undefined) {
                received = true;
                // assistant-loading を削除
                const newMessages = preMessages.filter(
                  (e) => e.role !== "assistant-loading"
                );

                content += parsedData.assistant_content;
                assistantMessageId = parsedData.message_id;
                showFeedbackButton = parsedData.receive_feedback || false;

                newMessages.push({
                  role: "assistant",
                  content,
                  messageId: assistantMessageId,
                  showFeedbackButton,
                });
                addMessage(newMessages, cref);

                numMessage = parsedData.num_messages;
              }
            }
          }
        })
        .catch(() => {
          showErrorMessage(ErrorMessage(500));
        });
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      showErrorMessage(ErrorMessage(500));
    }
  };

  const tabIndex = 2;

  return (
    <StyledSendButton
      tabIndex={tabIndex}
      onClick={onClickSend} // eslint-disable-line
      disabled={!isChatAvailable || disabled || requesting}
    >
      <SendIcon width={20} height={20} />
      送信
    </StyledSendButton>
  );
};

export default SendButton;
