import React, { useState } from "react";
import styled from "styled-components";
import Message from "./Message";
import { useChat } from "../context/ChatContext";
import { MessageState } from "../types/state/ChatState";
import { sleep } from "../utils/sleep";

const StyledDiv = styled.div`
  width: 100%;
  padding-inline: 1.25rem;
  overflow: hidden auto;
  overscroll-behavior: contain;
  margin-block: 1.25rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  @media screen and (min-width: 720px) {
    padding-inline: 2rem;
    margin-block: 2rem;
  }
`;

type ChatAreaProps = {
  conversationId: string;
  messages: MessageState[];
  areaRef: React.MutableRefObject<HTMLDivElement>;
};

const ChatArea = (props: ChatAreaProps) => {
  const { conversationId, messages, areaRef } = props;
  return (
    <StyledDiv ref={areaRef}>
      {messages.map((m) => (
        <Message
          id={m.messageId}
          conversationId={conversationId}
          showFeedbackButton={m.showFeedbackButton || false}
          role={m.role}
          content={m.content}
          key={m.messageId}
          links={m.links}
        />
      ))}
    </StyledDiv>
  );
};

type ChatProps = {
  cref: React.MutableRefObject<HTMLDivElement>;
  initialMessage: string;
  conversationId: string;
  remainingMessageCount: number;
};

const ClosingMessage =
  "ご相談ありがとうございました。この回答が少しでもお役に立てたなら幸いです。";

const Chat = (props: ChatProps) => {
  const [chat, addMessages] = useChat();
  const { cref, initialMessage, conversationId, remainingMessageCount } = props;

  const [chatClosed, setChatClosed] = useState<boolean>(false);

  if (chat.messages.length === 0) {
    sleep(600)
      .then(() => {
        addMessages(
          [
            {
              role: "assistant",
              content: initialMessage,
              messageId: `assistant-initial-${Date.now()}`,
            },
          ],
          cref
        );
      })
      .catch(() => {});
  }

  if (remainingMessageCount === 0 && !chatClosed) {
    addMessages(
      [
        {
          role: "assistant",
          content: ClosingMessage,
          messageId: `assistant-closing-${Date.now()}`,
          links: [
            {
              title: "こちらから弁護士を探すことができます",
              link: "https://www.bengo4.com/search/",
            },
          ],
        },
      ],
      cref
    );
    setChatClosed(true);
  }

  return (
    <ChatArea
      areaRef={cref}
      conversationId={conversationId}
      messages={chat.messages}
    />
  );
};

export default Chat;
