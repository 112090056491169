import { useRef, useState } from "react";
// eslint-disable-next-line
import axios, { AxiosError, AxiosResponse } from "axios";
import "./App.css";
import Chat from "./components/Chat";
import ChatTitle from "./components/ChatTitle";
import ConsentMessage from "./components/ConsentMessage";
import Disclaimer from "./components/Disclaimer";
import Footer from "./components/Footer";
import MessageCountIndicator from "./components/MessageCountIndicator";
import MessageInput from "./components/MessageInput";
import SendButton from "./components/SendButton";
import Supplement from "./components/Supplement";
import TemporarilyUnavailable from "./components/TemporarilyUnavailable";
import { ChatProvider } from "./context/ChatContext";
import ServiceLogo from "./images/service-logo.svg";
import { ErrorResponse } from "./types/response/ErrorResponse";
import { StartConversationResponse } from "./types/response/StartConversationResponse";

const MaxMessageCount = 5;

const App = () => {
  // サービス停止状態
  const [serviceAvailable, setServiceAvailable] = useState<boolean>(true);
  const stopService = () => {
    if (serviceAvailable) {
      setServiceAvailable(false);
    }
  };

  // ボタンの状態
  const [sendButtonDisabled, setSendButtonDisabled] = useState<boolean>(true);

  // インジケータの表示
  const [showIndicator, setShowIndicator] = useState<boolean>(true);

  // 相談可能かどうかを保持する state
  const [isChatAvailable, setIsChatAvailable] = useState<boolean>(false);

  // 相談内のチャットの回数
  const [messageCount, setMessageCount] = useState<number>(0);
  const updateMessageCount = (n: number | undefined) => {
    if (n !== undefined && n >= messageCount) {
      setMessageCount(n);

      const remaining = MaxMessageCount - n;
      updateRemainingMessageCount(remaining);
    }
  };

  // 相談内の残りチャット回数
  const [remainingMessageCount, setRemainingMessageCount] =
    useState<number>(MaxMessageCount);
  const updateRemainingMessageCount = (n: number) => {
    setRemainingMessageCount(n);
    if (n <= 0) {
      setIsChatAvailable(false);
    }
  };

  // ユーザの入力値を保持する state、入力があればボタンの状態を変更する
  const [userInput, setUserInput] = useState<string>("");
  const clearUserInput = () => {
    updateUserInput("");
  };
  const updateUserInput = (s: string) => {
    setUserInput(s);
    setSendButtonDisabled(s === "");
  };

  const chatRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  // チャットの ID を保持する state
  const [conversationId, setConversationId] = useState("");

  // 初回のメッセージを保持する state
  const [initialMessage, setInitialMessage] = useState<string>(
    "こんにちは。チャット法律相談です。\nどうされましたか？"
  );

  // チャットの開始
  const startConversation = () => {
    const base = process.env.REACT_APP_CHAT_API_BASE as string;
    const url = `${base}/conversations`;
    axios
      .post(url, null)
      .then((res: AxiosResponse<StartConversationResponse>) => {
        const { data, status } = res;
        if (status !== 201) {
          stopService();
          return;
        }
        setConversationId(data.id);
        setIsChatAvailable(true);
      })
      .catch((res: AxiosError<ErrorResponse>) => {
        try {
          const { data, status } = res.response as AxiosResponse<ErrorResponse>;
          switch (status) {
            case 400:
              setInitialMessage(data.error);
              break;
            default:
              stopService();
          }
        } catch (e) {
          stopService();
        } finally {
          setConversationId("-");
          setIsChatAvailable(false);
          setShowIndicator(false);
        }
      });
  };

  if (!serviceAvailable) {
    return (
      <div className="App-title">
        <div className="App-container">
          <ChatTitle />
          <TemporarilyUnavailable />
        </div>
        <Footer />
      </div>
    );
  }

  if (conversationId === "") {
    return (
      <div className="App-title">
        <div className="App-container">
          <ChatTitle />
          <Supplement />
          <Disclaimer />
          <ConsentMessage />
          <button
            className="App-startButton"
            type="button"
            onClick={startConversation}
          >
            同意して相談をはじめる
          </button>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="App">
      <div className="App-conversation-board">
        <div className="App-header">
          <img
            src={ServiceLogo}
            className="App-logo"
            alt="チャット法律相談(α)"
          />
        </div>
        <div className="App-body">
          <ChatProvider>
            <Chat
              cref={chatRef}
              initialMessage={initialMessage}
              conversationId={conversationId}
              remainingMessageCount={remainingMessageCount}
            />
            <div className="App-Message-input-aria">
              <MessageCountIndicator
                showIndicator={showIndicator}
                remainingMessageCount={remainingMessageCount}
              />
              <MessageInput
                userInput={userInput}
                updateUserInput={updateUserInput}
                isChatAvailable={isChatAvailable}
              />
              <SendButton
                clearUserInput={clearUserInput}
                userInput={userInput}
                conversationId={conversationId}
                cref={chatRef}
                disabled={sendButtonDisabled}
                isChatAvailable={isChatAvailable}
                updateMessageCount={updateMessageCount}
              />
            </div>
          </ChatProvider>
        </div>
      </div>
    </div>
  );
};

export default App;
