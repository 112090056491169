import styled from "styled-components";

const StyledDiv = styled.div`
  color: #72706e;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const ConsentMessage = () => (
  <StyledDiv>
    <p>
      <a href="/terms-of-service/">利用規約</a>と
      <a href="/privacy-policy/">プライバシーポリシー</a>
      に同意のうえご利用ください
    </p>
  </StyledDiv>
);

export default ConsentMessage;
