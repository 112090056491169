import styled from "styled-components";
import Logo from "../images/logo-center.svg";

const StyledDiv = styled.div`
  text-align: center;

  & h1 {
    margin-block-start: 2em;
    margin-block-end: initial;
  }

  & img {
    width: 380px;
    max-width: 80%;
  }
`;

const ChatTitle = () => (
  <StyledDiv>
    <h1>
      <img src={Logo} alt="弁護士ドットコム チャット法律相談（α）" />
    </h1>
  </StyledDiv>
);

export default ChatTitle;
