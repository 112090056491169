import React, { useState, createContext, useMemo, useContext } from "react";
import { ChatState, MessageState } from "../types/state/ChatState";

export const ChatContext = createContext<
  [ChatState, React.Dispatch<React.SetStateAction<ChatState>>]
>([{} as ChatState, {} as React.Dispatch<React.SetStateAction<ChatState>>] as [
  ChatState,
  React.Dispatch<React.SetStateAction<ChatState>>
]);

interface ChatContextProps {
  children: React.ReactNode;
}

export const ChatProvider = ({ children }: ChatContextProps) => {
  const defaultState: ChatState = {
    messages: [],
  };
  const [chat, setChat] = useState<ChatState>(defaultState);
  const value = useMemo(
    (): [ChatState, React.Dispatch<React.SetStateAction<ChatState>>] => [
      chat,
      setChat,
    ],
    [chat, setChat]
  );
  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChat = (): [
  ChatState,
  (ms: MessageState[], cref: React.MutableRefObject<HTMLDivElement>) => void
] => {
  const [chat, setChat] = useContext(ChatContext);
  const addMessages = (
    ms: MessageState[],
    cref: React.MutableRefObject<HTMLDivElement>
  ) => {
    const sleep = async () => {
      await new Promise((resolve) => setTimeout(resolve, 300)); // eslint-disable-line  no-promise-executor-return
    };

    const newMessages = [...chat.messages];
    ms.forEach((m) => {
      newMessages.push(m);
    });

    const tmpChatState = {
      messages: newMessages,
    };
    setChat(tmpChatState);

    if (cref !== undefined) {
      sleep()
        .then(() => {
          cref.current.scrollTo({
            top: cref.current.scrollHeight,
          });
        })
        .catch(() => {});
    }
  };

  return [chat, addMessages];
};
