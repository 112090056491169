import styled from "styled-components";

const StyledDiv = styled.div`
  font-size: .75rem;

  & p {
    width: 100%;
    max-width: 720px;
    margin-inline: auto;
    margin-block: 1.5em 0;
    padding-inline: 1.5em;
    text-align: center;
  }
`;

const Supplement = () => (
  <StyledDiv>
    <p>※α版では離婚・男女問題にのみ対応です</p>
  </StyledDiv>
);

export default Supplement;
