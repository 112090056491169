import React from "react";
import styled, { keyframes } from "styled-components";

// Create the keyframes
const shadowPulse = keyframes`
  33% {
    background: #FFF;
    box-shadow: -24px 0 #B0AFAF, 24px 0 #FFF;
  }
  66% {
    background: #B0AFAF;
    box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  }
  100% {
    background: #FFF;
    box-shadow: -24px 0 #FFF, 24px 0 #B0AFAF;
  }
`;

const StyledLoader = styled.div`
  width: .75rem;
  height: .75rem;
  border-radius: 50%;
  margin: 1rem auto;
  position: relative;
  background: transparent;
  box-shadow: -24px 0 #fff, 24px 0 #fff;
  animation: ${shadowPulse} 2s linear infinite;
`;

const Loader = () => <StyledLoader />;

export default Loader;