import styled from "styled-components";
import { MessageInputProps } from "../types/props/MessageInputProps";

const StyledTextArea = styled.textarea`
  width: 100%;
  font-size: 1rem;
  padding: 1em .75em;
  line-height: 1.4;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  box-shadow: inset 3px 3px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  resize:none;

  &:disabled {
    opacity: .3;
    pointer-events: none;
  }
`;

const MessageInput = (props: MessageInputProps) => {
  const { userInput, updateUserInput, isChatAvailable } = props;
  const tabIndex = 1;
  const placeholder = isChatAvailable ? "相談したいことは何ですか？" : "";
  return (
    <StyledTextArea
      placeholder={placeholder}
      value={userInput}
      tabIndex={tabIndex}
      onChange={(e) => {
        updateUserInput(e.target.value);
      }}
      disabled={!isChatAvailable}
    />
  );
};

export default MessageInput;
