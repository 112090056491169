import styled, { keyframes } from "styled-components";
import { useState } from "react";
// eslint-disable-next-line
import axios, { AxiosError, AxiosResponse } from "axios";
import {
  FeedbackProps,
  FeedbackButtonProps,
} from "../types/props/FeedbackProps";
import { FeedbackResponse } from "../types/response/FeedbackResponse";
import { ErrorResponse } from "../types/response/ErrorResponse";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledFeedbackAreaDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  margin-block-start: 0.5em;
`;

const SendFeedbackError = "フィードバックの送信に失敗しました。";

const StyledFeedbackButton = styled.button`
  &.good {
    // good button
    ::before {
      content: "";
      display: block;
      width: 40px;
      height: 40px;
      border: 1px solid #ddd;
      border-radius: 6px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-image: url("../../images/icon-good-effect.svg");
    }

    &:hover::before {
      border: 1px solid #72706e;
      background-position: -3520px 0;
      transition: background 1s steps(55);
    }
  }
  &.bad {
    // bad button
    ::before {
      content: "";
      display: block;
      width: 40px;
      height: 40px;
      border: 1px solid #ddd;
      border-radius: 6px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-image: url("../../images/icon-bad.svg");
    }

    &:hover::before {
      border: 1px solid #72706e;
    }
  }
  &.sent {
    // feedback 送信済み
    animation: ${fadeIn} 0.3s ease-in-out;
    ::before {
      background-color: #e9e9e9;
      border: transparent;
    }
    &:hover::before {
      background-position: 0 0;
      border: transparent;
      cursor: not-allowed;
    }
  }
`;

const SendFeedback = (
  conversationId: string,
  messageId: string,
  feedback: number,
  setSentFeedback: (n: number) => void
) => {
  const base = process.env.REACT_APP_CHAT_API_BASE as string;
  const url = `${base}/conversations/${conversationId}/messages/${messageId}/feedback`;
  axios
    .put(url, {
      feedback,
    })
    .then((res: AxiosResponse<FeedbackResponse>) => {
      const { data, status } = res;
      if (status !== 200 || !data.result) {
        // eslint-disable-next-line no-alert
        alert(SendFeedbackError);
        return;
      }

      setSentFeedback(feedback);
    })
    .catch((res: AxiosError<ErrorResponse>) => {
      try {
        const { data } = res.response as AxiosResponse<ErrorResponse>;
        if (data.error !== undefined) {
          // eslint-disable-next-line no-alert
          alert(`${SendFeedbackError} ${data.error}`);
          return;
        }

        // eslint-disable-next-line no-alert
        alert(SendFeedbackError);
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert(SendFeedbackError);
      }
    });
};

const GoodFeedback = 1;
const BadFeedback = -1;
const NeutralFeedback = 0;

const FeedbackButton = (props: FeedbackButtonProps) => {
  const { feedback, hasSent, conversationId, messageId, setSentFeedback } =
    props;

  const buttonType = feedback === GoodFeedback ? "good" : "bad";
  const buttonClassName = hasSent ? `sent ${buttonType}` : buttonType;

  if (hasSent) {
    return <StyledFeedbackButton className={buttonClassName} disabled />;
  }

  return (
    <StyledFeedbackButton
      className={buttonType}
      onClick={() =>
        SendFeedback(
          conversationId as string,
          messageId as string,
          feedback,
          setSentFeedback as (n: number) => void
        )
      }
    />
  );
};

const Feedback = (props: FeedbackProps) => {
  const { conversationId, messageId, showFeedbackButton } = props;
  const [sentFeedback, setSentFeedback] = useState(0);

  if (!showFeedbackButton) {
    return null;
  }

  if (sentFeedback === NeutralFeedback) {
    return (
      <StyledFeedbackAreaDiv>
        <FeedbackButton
          feedback={GoodFeedback}
          hasSent={false}
          conversationId={conversationId}
          messageId={messageId}
          setSentFeedback={setSentFeedback}
        />
        <FeedbackButton
          feedback={BadFeedback}
          hasSent={false}
          conversationId={conversationId}
          messageId={messageId}
          setSentFeedback={setSentFeedback}
        />
      </StyledFeedbackAreaDiv>
    );
  }

  return (
    <StyledFeedbackAreaDiv>
      <FeedbackButton feedback={sentFeedback} hasSent />
    </StyledFeedbackAreaDiv>
  );
};

export default Feedback;
